<template>
  <div class="vx-row">
    <div class="vx-col md:w-3/4">
      <vx-card>
        <div class="vx-row mt-5">
          <div class="vx-col md:w-1/2">
          </div>
          <div class="vx-col md:w-1/2 text-right">
            Date: {{DateOrd | moment("calendar", "July 10 2011") }}
          </div>
        </div>
        <div class="vx-row mt-5">
          <div class="vx-col w-full text-center">
            <span  style="font-size: 20px; font-weight: bold; text-decoration: underline">ORDONNANCE LUNETTE</span>
          </div>
        </div>
        <div class="vx-row mt-5">
          <div class="vx-col md:w-1/2">
            N° Ordo: {{RefOrd}}
          </div>
        </div>
        <div class="vx-row mt-5">
          <div class="vx-col md:w-1/2 text-center">
            Patient: {{client.Nom}} {{client.Prenoms}}
          </div>
        </div>
        <div class="vx-row mt-5">
          <div class="vx-col w-full">
            <table class="w-full"  cellpadding="0" cellspacing="0">
              <thead>
                <tr>
                  <th style="border-bottom-width: 0px; border-left-width: 0px; border-top-width: 0px;border-right-width: 0px;" colspan="1"></th>
                  <th class="text-center" style="border-bottom-width: 0px; border-left-width: 0px; border-top-width: 0px" colspan="4">

                    <div class="vx-col md-full">90°</div>
                    <!-- <div class="vx-col md-full text-center">
                      <img class="mr-0" src="@/assets/images/logo/accolade.svg" alt="groupe-logo" >
                    </div> -->
                    <div class="vx-row">
                      <div class="vx-col md:w-1/2">180°</div>
                      <div class="vx-col md:w-1/2">0°</div>
                    </div>
                    <span style="font-size: 30px">OD</span>

                  </th>
                  <th class="text-center" style="border-bottom-width: 0px;border-top-width: 0px; border-right-width: 0px" colspan="4">
                    <div class="vx-col md-full">90°</div>
                    <div class="vx-row">
                      <div class="vx-col md:w-1/2">180°</div>
                      <div class="vx-col md:w-1/2">0°</div>
                    </div>
                    <span style="font-size: 30px">OG</span>
                  </th>
                </tr>
                <tr>
                  <th style="border: none"></th>
                  <th style="border-bottom-width: 0px; border-right-width: 0px;">Sphère</th>
                  <th style="border-bottom-width: 0px; border-right-width: 0px;">Cyl</th>
                  <th style="border-bottom-width: 0px; border-right-width: 0px;">Axe</th>
                  <th style="border-bottom-width: 0px;">Av</th>
                  <th style="border-bottom-width: 0px; border-right-width: 0px;">Sphère</th>
                  <th style="border-bottom-width: 0px; border-right-width: 0px;">Cyl</th>
                  <th style="border-bottom-width: 0px; border-right-width: 0px;">Axe</th>
                  <th style="border-bottom-width: 0px;">Av</th>
                </tr>
                <tr>
                  <th style="border-bottom-width: 0px; border-right-width: 0px;">VISION DE LOIN</th>
                  <th style="border-bottom-width: 0px; border-right-width: 0px;">
                    <div class="vx-row">
                      <div v-if="!OdVlSphEdit" class="vx-col w-full text-center">
                        <feather-icon class="cursor-pointer" icon="EditIcon" svgClasses="h-4 w-4" @click="OdVlSphEditFunction"></feather-icon>
                      </div>
                      <div v-if="OdVlSphEdit" class="vx-col w-full text-center">
                        <vs-input @blur="OdVlSphEditFunction" v-model="OdVlSph" class="w-full" />
                      </div>
                      <div v-if="OdVlSph" class="vx-col w-full text-center">
                        <span v-if="parseFloat(OdVlSph) >= 0">
                          +{{ formatterNumber(parseFloat(OdVlSph))}}
                        </span>
                        <span v-else>
                          {{formatterNumber(parseFloat(OdVlSph))}}
                        </span>
                      </div>
                    </div>
                  </th>
                  <th style="border-bottom-width: 0px; border-right-width: 0px;">
                    <div class="vx-row">
                      <div v-if="!OdVlCylEdit" class="vx-col w-full text-center">
                        <feather-icon class="cursor-pointer" icon="EditIcon" svgClasses="h-4 w-4" @click="OdVlCylEditFunction"></feather-icon>
                      </div>
                      <div v-if="OdVlCylEdit" class="vx-col w-full text-center">
                        <vs-input @blur="OdVlCylEditFunction" v-model="OdVlCyl" class="w-full" />
                      </div>
                      <div v-if="OdVlCyl" class="vx-col w-full text-center">
                        <span v-if="parseFloat(OdVlCyl) >= 0">
                          +{{ formatterNumber(parseFloat(OdVlCyl))}}
                        </span>
                        <span v-else>
                          {{formatterNumber(parseFloat(OdVlCyl))}}
                        </span>
                      </div>
                    </div>
                  </th>
                  <th style="border-bottom-width: 0px; border-right-width: 0px;">
                    <div class="vx-row">
                      <div v-if="!OdVlAxeEdit" class="vx-col w-full text-center">
                        <feather-icon class="cursor-pointer" icon="EditIcon" svgClasses="h-4 w-4" @click="OdVlAxeEditFunction"></feather-icon>
                      </div>
                      <div v-if="OdVlAxeEdit" class="vx-col w-full text-center">
                        <vs-input @blur="OdVlAxeEditFunction" v-model="OdVlAxe" class="w-full" />
                      </div>
                      <div v-if="OdVlAxe" class="vx-col w-full text-center">
                        <span v-if="parseFloat(OdVlAxe) >= 0">
                          {{ parseFloat(OdVlAxe)}}°
                        </span>
                      </div>
                    </div>
                  </th>
                  <th style="border-bottom-width: 0px;">
                    <div class="vx-row">
                      <div v-if="!OdVlAvEdit" class="vx-col w-full text-center">
                        <feather-icon class="cursor-pointer" icon="EditIcon" svgClasses="h-4 w-4" @click="OdVlAvEditFunction"></feather-icon>
                      </div>
                      <div v-if="OdVlAvEdit" class="vx-col w-full text-center">
                        <vs-input @blur="OdVlAvEditFunction" v-model="OdVlAv" class="w-full" />
                      </div>
                      <div v-if="OdVlAv" class="vx-col w-full text-center">
                        <span v-if="parseFloat(OdVlAv) >= 0">
                          {{ parseFloat(OdVlAv)}}/10
                        </span>
                      </div>
                    </div>
                  </th>
                  <th style="border-bottom-width: 0px; border-right-width: 0px;">
                    <div class="vx-row">
                      <div v-if="!OgVlSphEdit" class="vx-col w-full text-center">
                        <feather-icon class="cursor-pointer" icon="EditIcon" svgClasses="h-4 w-4" @click="OgVlSphEditFunction"></feather-icon>
                      </div>
                      <div v-if="OgVlSphEdit" class="vx-col w-full text-center">
                        <vs-input @blur="OgVlSphEditFunction" v-model="OgVlSph" class="w-full" />
                      </div>
                      <div v-if="OgVlSph" class="vx-col w-full text-center">
                        <span v-if="parseFloat(OgVlSph) >= 0">
                          +{{ formatterNumber(parseFloat(OgVlSph))}}
                        </span>
                        <span v-else>
                          {{formatterNumber(parseFloat(OgVlSph))}}
                        </span>
                      </div>
                    </div>
                  </th>
                  <th style="border-bottom-width: 0px; border-right-width: 0px;">
                    <div class="vx-row">
                      <div v-if="!OgVlCylEdit" class="vx-col w-full text-center">
                        <feather-icon class="cursor-pointer" icon="EditIcon" svgClasses="h-4 w-4" @click="OgVlCylEditFunction"></feather-icon>
                      </div>
                      <div v-if="OgVlCylEdit" class="vx-col w-full text-center">
                        <vs-input @blur="OgVlCylEditFunction" v-model="OgVlCyl" class="w-full" />
                      </div>
                      <div v-if="OgVlCyl" class="vx-col w-full text-center">
                        <span v-if="parseFloat(OgVlCyl) >= 0">
                          +{{ formatterNumber(parseFloat(OdVlCyl))}}
                        </span>
                        <span v-else>
                          {{formatterNumber(parseFloat(OgVlCyl))}}
                        </span>
                      </div>
                    </div>
                  </th>
                  <th style="border-bottom-width: 0px; border-right-width: 0px;">
                    <div class="vx-row">
                      <div v-if="!OgVlAxeEdit" class="vx-col w-full text-center">
                        <feather-icon class="cursor-pointer" icon="EditIcon" svgClasses="h-4 w-4" @click="OgVlAxeEditFunction"></feather-icon>
                      </div>
                      <div v-if="OgVlAxeEdit" class="vx-col w-full text-center">
                        <vs-input @blur="OgVlAxeEditFunction" v-model="OgVlAxe" class="w-full" />
                      </div>
                      <div v-if="OgVlAxe" class="vx-col w-full text-center">
                        <span v-if="parseFloat(OgVlAxe) >= 0">
                          {{ parseFloat(OgVlAxe)}}°
                        </span>
                      </div>
                    </div>
                  </th>
                  <th style="border-bottom-width: 0px;">
                    <div class="vx-row">
                      <div v-if="!OgVlAvEdit" class="vx-col w-full text-center">
                        <feather-icon class="cursor-pointer" icon="EditIcon" svgClasses="h-4 w-4" @click="OgVlAvEditFunction"></feather-icon>
                      </div>
                      <div v-if="OgVlAvEdit" class="vx-col w-full text-center">
                        <vs-input @blur="OgVlAvEditFunction" v-model="OgVlAv" class="w-full" />
                      </div>
                      <div v-if="OgVlAv" class="vx-col w-full text-center">
                        <span v-if="parseFloat(OgVlAv) >= 0">
                          {{ parseFloat(OgVlAv)}}/10
                        </span>
                      </div>
                    </div>
                  </th>
                </tr>
                <tr>
                  <th colspan="1" style="border-bottom-width: 0px; border-right-width: 0px;">
                    Addition:
                  </th>
                  <th colspan="4" style="border-bottom-width: 0px; border-left-width: 0px">
                    <div class="vx-row">
                      <div v-if="!OdAdditionEdit" class="vx-col w-full text-center">
                        <feather-icon class="cursor-pointer" icon="EditIcon" svgClasses="h-4 w-4" @click="OdAdditionEditFunction"></feather-icon>
                      </div>
                      <div v-if="OdAdditionEdit" class="vx-col w-full text-center">
                        <vs-input @blur="OdAdditionEditFunction" v-model="OdAddition" class="w-full" />
                      </div>
                      <div v-if="OdAddition" class="vx-col w-full text-center">
                        <span v-if="parseFloat(OdAddition) >= 0">
                          +{{ formatterNumber(parseFloat(OdAddition))}}
                        </span>
                        <span v-else>
                          {{formatterNumber(parseFloat(OdAddition))}}
                        </span>
                      </div>
                    </div>
                  </th>
                  <th colspan="1" style="border-bottom-width: 0px; border-right-width: 0px;">
                    Addition:
                  </th>
                  <th colspan="3" style="border-bottom-width: 0px;border-left-width: 0px">
                    <div class="vx-row">
                      <div v-if="!OgAdditionEdit" class="vx-col w-full text-center">
                        <feather-icon class="cursor-pointer" icon="EditIcon" svgClasses="h-4 w-4" @click="OgAdditionEditFunction"></feather-icon>
                      </div>
                      <div v-if="OgAdditionEdit" class="vx-col w-full text-center">
                        <vs-input @blur="OgAdditionEditFunction" v-model="OgAddition" class="w-full" />
                      </div>
                      <div v-if="OgAddition" class="vx-col w-full text-center">
                        <span v-if="parseFloat(OgAddition) >= 0">
                          +{{ formatterNumber(parseFloat(OgAddition))}}
                        </span>
                        <span v-else>
                          {{formatterNumber(parseFloat(OgAddition))}}
                        </span>
                      </div>
                    </div>
                  </th>

                </tr>
                <tr>
                  <th style="border-right-width: 0px;">VISION DE PRES</th>
                  <th style="border-right-width: 0px;">
                    <div class="vx-row">
                      <div v-if="!OdVpSphEdit" class="vx-col w-full text-center">
                        <feather-icon class="cursor-pointer" icon="EditIcon" svgClasses="h-4 w-4" @click="OdVpSphEditFunction"></feather-icon>
                      </div>
                      <div v-if="OdVpSphEdit" class="vx-col w-full text-center">
                        <vs-input @blur="OdVpSphEditFunction" v-model="OdVpSph" class="w-full" />
                      </div>
                      <div v-if="OdVlSph" class="vx-col w-full text-center">
                        <span v-if="parseFloat(OdVpSph) >= 0">
                          +{{ formatterNumber(parseFloat(OdVpSph))}}
                        </span>
                        <span v-else>
                          {{formatterNumber(parseFloat(OdVpSph))}}
                        </span>
                      </div>
                    </div>
                  </th>
                  <th style="border-right-width: 0px;">
                    <div class="vx-row">
                      <div v-if="!OdVpCylEdit" class="vx-col w-full text-center">
                        <feather-icon class="cursor-pointer" icon="EditIcon" svgClasses="h-4 w-4" @click="OdVpCylEditFunction"></feather-icon>
                      </div>
                      <div v-if="OdVpCylEdit" class="vx-col w-full text-center">
                        <vs-input @blur="OdVpCylEditFunction" v-model="OdVpCyl" class="w-full" />
                      </div>
                      <div v-if="OdVpCyl" class="vx-col w-full text-center">
                        <span v-if="parseFloat(OdVpCyl) >= 0">
                          +{{ formatterNumber(parseFloat(OdVpCyl))}}
                        </span>
                        <span v-else>
                          {{formatterNumber(parseFloat(OdVpCyl))}}
                        </span>
                      </div>
                    </div>
                  </th>
                  <th style="border-right-width: 0px;">
                    <div class="vx-row">
                      <div v-if="!OdVpAxeEdit" class="vx-col w-full text-center">
                        <feather-icon class="cursor-pointer" icon="EditIcon" svgClasses="h-4 w-4" @click="OdVpAxeEditFunction"></feather-icon>
                      </div>
                      <div v-if="OdVpAxeEdit" class="vx-col w-full text-center">
                        <vs-input @blur="OdVpAxeEditFunction" v-model="OdVpAxe" class="w-full" />
                      </div>
                      <div v-if="OdVpAxe" class="vx-col w-full text-center">
                        <span v-if="parseFloat(OdVpAxe) >= 0">
                          {{ parseFloat(OdVpAxe)}}°
                        </span>
                      </div>
                    </div>
                  </th>
                  <th>
                    <div class="vx-row">
                      <div v-if="!OdVpAvEdit" class="vx-col w-full text-center">
                        <feather-icon class="cursor-pointer" icon="EditIcon" svgClasses="h-4 w-4" @click="OdVpAvEditFunction"></feather-icon>
                      </div>
                      <div v-if="OdVpAvEdit" class="vx-col w-full text-center">
                        <vs-input @blur="OdVpAvEditFunction" v-model="OdVpAv" class="w-full" />
                      </div>
                      <div v-if="OdVpAv" class="vx-col w-full text-center">
                        <span v-if="parseFloat(OdVpAv) >= 0">
                          {{ parseFloat(OdVpAv)}}
                        </span>
                      </div>
                    </div>
                  </th>
                  <th style="border-right-width: 0px;">
                    <div class="vx-row">
                      <div v-if="!OgVpSphEdit" class="vx-col w-full text-center">
                        <feather-icon class="cursor-pointer" icon="EditIcon" svgClasses="h-4 w-4" @click="OgVpSphEditFunction"></feather-icon>
                      </div>
                      <div v-if="OgVpSphEdit" class="vx-col w-full text-center">
                        <vs-input @blur="OgVpSphEditFunction" v-model="OgVpSph" class="w-full" />
                      </div>
                      <div v-if="OgVlSph" class="vx-col w-full text-center">
                        <span v-if="parseFloat(OgVpSph) >= 0">
                          +{{ formatterNumber(parseFloat(OgVpSph))}}
                        </span>
                        <span v-else>
                          {{formatterNumber(parseFloat(OgVpSph))}}
                        </span>
                      </div>
                    </div>
                  </th>
                  <th style="border-right-width: 0px;">
                    <div class="vx-row">
                      <div v-if="!OgVpCylEdit" class="vx-col w-full text-center">
                        <feather-icon class="cursor-pointer" icon="EditIcon" svgClasses="h-4 w-4" @click="OgVpCylEditFunction"></feather-icon>
                      </div>
                      <div v-if="OgVpCylEdit" class="vx-col w-full text-center">
                        <vs-input @blur="OgVpCylEditFunction" v-model="OgVpCyl" class="w-full" />
                      </div>
                      <div v-if="OgVpCyl" class="vx-col w-full text-center">
                        <span v-if="parseFloat(OgVpCyl) >= 0">
                          +{{ formatterNumber(parseFloat(OgVpCyl))}}
                        </span>
                        <span v-else>
                          {{formatterNumber(parseFloat(OgVpCyl))}}
                        </span>
                      </div>
                    </div>
                  </th>
                  <th style="border-right-width: 0px;">
                    <div class="vx-row">
                      <div v-if="!OgVpAxeEdit" class="vx-col w-full text-center">
                        <feather-icon class="cursor-pointer" icon="EditIcon" svgClasses="h-4 w-4" @click="OgVpAxeEditFunction"></feather-icon>
                      </div>
                      <div v-if="OgVpAxeEdit" class="vx-col w-full text-center">
                        <vs-input @blur="OgVpAxeEditFunction" v-model="OgVpAxe" class="w-full" />
                      </div>
                      <div v-if="OgVpAxe" class="vx-col w-full text-center">
                        <span v-if="parseFloat(OgVpAxe) >= 0">
                          {{ parseFloat(OgVpAxe)}}°
                        </span>
                      </div>
                    </div>
                  </th>
                  <th>
                    <div class="vx-row">
                      <div v-if="!OgVpAvEdit" class="vx-col w-full text-center">
                        <feather-icon class="cursor-pointer" icon="EditIcon" svgClasses="h-4 w-4" @click="OgVpAvEditFunction"></feather-icon>
                      </div>
                      <div v-if="OgVpAvEdit" class="vx-col w-full text-center">
                        <vs-input @blur="OgVpAvEditFunction" v-model="OgVpAv" class="w-full" />
                      </div>
                      <div v-if="OgVpAv" class="vx-col w-full text-center">
                        <span v-if="parseFloat(OdVpAv) >= 0">
                          {{ parseFloat(OdVpAv)}}
                        </span>
                      </div>
                    </div>
                  </th>
                </tr>
            </thead>
            </table>
          </div>
        </div>
        <div class="vx-row mt-5">
          <div class="vx-col md:w-1/2 mt-4">
            <h3>TYPE DE VERRE:</h3>
            <div class="vx-row">
              <div class="vx-col md:w-1/2 mt-4">
                <p>Simple Foyer</p>
              </div>
              <div class="vx-col md:w-1/2 mt-4">
                <vs-radio v-model="typeVerre" vs-value="Simple foyer"></vs-radio>
              </div>
              <div class="vx-col md:w-1/2 mt-4">
                <p>Double Foyer</p>
              </div>
              <div class="vx-col md:w-1/2 mt-4">
                <vs-radio v-model="typeVerre" vs-value="Double foyer"></vs-radio>
              </div>
              <div class="vx-col md:w-1/2 mt-4">
                <p>Progressif</p>
              </div>
              <div class="vx-col md:w-1/2 mt-4">
                <vs-radio v-model="typeVerre" vs-value="Progessif"></vs-radio>
              </div>
            </div>
          </div>
          <div class="vx-col md:w-1/2">
            <h3>OPTION:</h3>
            <div class="vx-row">
              <div class="vx-col md:w-1/2 mt-4">
                <p>Photochromique</p>
              </div>
              <div class="vx-col md:w-1/2 mt-4">
                <vs-checkbox label="PhotoChrom" v-model="PhotoChrom" class="w-full" ></vs-checkbox>
              </div>
              <div class="vx-col md:w-1/2 mt-4">
                <p>Anti-Reflet</p>
              </div>
              <div class="vx-col md:w-1/2 mt-4">
                <vs-checkbox label="AntiReflet" v-model="AntiReflet" class="w-full" ></vs-checkbox>
              </div>
              <div class="vx-col md:w-1/2 mt-4">
                <p>Teinte</p>
              </div>
              <div class="vx-col md:w-1/2 mt-4">
                <vs-input v-model="Teinte" class="w-full" />
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
    <div class="vx-col md:w-1/4">
      <vx-card>
        <vs-select label="Client" autocomplete class="selectExample w-full select-large mt-4" v-model="IdClient" @change="getClientById(IdClient)">
          <vs-select-item class="w-full" :key="index" :value="item._id" :text="`${item.Nom} ${item.Prenoms}`" v-for="(item,index) in clientsData" />
        </vs-select>
        <div class="mt-4">
          <label class="text-sm">Date</label>
          <flat-pickr v-model="DateOrd" :config="configdateTimePicker" class="w-full" name="DateOrd" />
        </div>
        <vs-select label="Prescripteurs" autocomplete class="selectExample w-full select-large mt-4" v-model="Prescripteur">
          <vs-select-item class="w-full" :key="index" :value="item._id" :text="`${item.NomPresc} ${item.PrenomPresc}`" v-for="(item,index) in prescripteurs" />
        </vs-select>

        <div class="mt-4">
          <vs-button class="w-full" color="primary" @click="SaveOrdonnance">Enregistrer</vs-button>
        </div>

      </vx-card>
    </div>

  </div>
</template>
<script>
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
export default {
  components: {
    flatPickr
  },
  data () {
    return {
      configdateTimePicker: {
        dateFormat: 'd F Y',
        maxDate: new Date()
      },
      IdUser: null,
      client: {
        Nom: '',
        Prenoms: ''
      },
      idOfficine: null,
      activeUserInfos: null,
      IdClient: null,
      RefOrd: '',
      Prescripteur: null,
      DateOrd: null,
      OdVpSph: '',
      OdVpCyl: '',
      OdVpAxe: '',
      OdVpAv: '',
      OdVlSph: '',
      OdVlCyl: '',
      OdVlAxe: '',
      OdVlAv: '',
      OdAddition: '',
      OgVlSph: '',
      OgVlCyl: '',
      OgVlAxe: '',
      OgVlAv: '',
      OgVpSph: '',
      OgVpCyl: '',
      OgVpAxe: '',
      OgVpAv: '',
      OgAddition: '',
      PhotoChrom: false,
      AntiReflet: false,
      typeVerre: '',
      Teinte: '',

      OdVlSphEdit: false,
      OdVlCylEdit: false,
      OdVlAxeEdit: false,
      OdVlAvEdit: false,
      OgVlSphEdit: false,
      OgVlCylEdit: false,
      OgVlAxeEdit: false,
      OgVlAvEdit: false,
      OdAdditionEdit: false,

      OgAdditionEdit: false,
      OdVpSphEdit: false,
      OdVpCylEdit: false,
      OdVpAxeEdit: false,
      OdVpAvEdit: false,
      OgVpSphEdit: false,
      OgVpCylEdit: false,
      OgVpAxeEdit: false,
      OgVpAvEdit: false,
      disableButtomSave: true


    }
  },
  computed: {
    prescripteurs () {
      return this.$store.state.prescripteur.prescripteurs
    },
    clientsData () {
      return this.$store.state.client.clients
    },
    officines () {
      return this.$store.state.officine.officines
    }
  },
  methods: {
    griseButton () {
      if (this.RefOrd !== '') {
        this.disableButtomSave = false
      } else {
        this.disableButtomSave = true
      }
    },

    OdVlSphEditFunction () {
      this.OdVlSphEdit = !this.OdVlSphEdit
    },
    OgVlSphEditFunction () {
      this.OgVlSphEdit = !this.OgVlSphEdit
    },
    OdVlCylEditFunction () {
      this.OdVlCylEdit = !this.OdVlCylEdit
    },
    OgVlCylEditFunction () {
      this.OgVlCylEdit = !this.OgVlCylEdit
    },
    OdVlAxeEditFunction () {
      this.OdVlAxeEdit = !this.OdVlAxeEdit
    },
    OgVlAxeEditFunction () {
      this.OgVlAxeEdit = !this.OgVlAxeEdit
    },
    OdVlAvEditFunction () {
      this.OdVlAvEdit = !this.OdVlAvEdit
    },
    OgVlAvEditFunction () {
      this.OgVlAvEdit = !this.OgVlAvEdit
    },
    OgAdditionEditFunction () {
      this.OgAdditionEdit = !this.OgAdditionEdit
      this.OgVpSph = parseFloat(this.OgVlSph) + parseFloat(this.OgAddition)
      /* this.OgVpCyl = parseFloat(this.OgVlCyl) + parseFloat(this.OgAddition) */


    },
    OdAdditionEditFunction () {
      this.OdAdditionEdit = !this.OdAdditionEdit

      this.OdVpSph = parseFloat(this.OdVlSph) + parseFloat(this.OdAddition)
      /* this.OdVpCyl = parseFloat(this.OdVlCyl) + parseFloat(this.OdAddition) */

    },
    OdVpSphEditFunction () {
      this.OdVpSphEdit = !this.OdVpSphEdit
    },
    OdVpCylEditFunction () {
      this.OdVpCylEdit = !this.OdVpCylEdit
    },
    OdVpAxeEditFunction () {
      this.OdVpAxeEdit = !this.OdVpAxeEdit
    },
    OdVpAvEditFunction () {
      this.OdVpAvEdit = !this.OdVpAvEdit
    },
    OgVpSphEditFunction () {
      this.OgVpSphEdit = !this.OgVpSphEdit
    },
    OgVpCylEditFunction () {
      this.OgVpCylEdit = !this.OgVpCylEdit
    },
    OgVpAxeEditFunction () {
      this.OgVpAxeEdit = !this.OgVpAxeEdit
    },
    OgVpAvEditFunction () {
      this.OgVpAvEdit = !this.OgVpAvEdit
    },

    formatterNumber (nbre) {
      return  nbre ? nbre.toFixed(2) : ''
    },

    SaveOrdonnance () {
      const payload = {
        IdUser: this.IdUser,
        Prescripteur: this.Prescripteur,
        DateOrd: new Date(new Date(this.DateOrd)).toISOString().split('T')[0],
        OdVpSph: this.OdVpSph,
        OdVpCyl: this.OdVpCyl,
        OdVpAxe: this.OdVpAxe,
        OdVpAv: this.OdVpAv,
        OdVlSph: this.OdVlSph,
        OdVlCyl: this.OdVlCyl,
        OdVlAxe: this.OdVlAxe,
        OdVlAv: this.OdVlAv,
        OdAddition: this.OdAddition,
        OgVlSph: this.OgVlSph,
        OgVlCyl: this.OgVlCyl,
        OgVlAxe: this.OgVlAxe,
        OgVlAv: this.OgVlAv,
        OgVpSph: this.OgVpSph,
        OgVpCyl: this.OgVpCyl,
        OgVpAxe: this.OgVpAxe,
        OgVpAv: this.OgVpAv,
        OgAddition: this.OgAddition,
        PhotoChrom: this.PhotoChrom,
        AntiReflet: this.AntiReflet,
        Teinte: this.Teinte,
        typeVerre: this.typeVerre,
        id: this.IdClient

      }

      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('client/addOrdonnance', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary',
            time:5000
          })
          this.$router.push('/apps/ordonnance/ordonnance-list').catch((err) => { console.log(err) })
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },

    getPrescripteurs () {
      this.$store.dispatch('prescripteur/getPrescripteurs')
        .then(() => {

        })
        .catch(err => { console.log(err) })
    },
    activeUserInfo () {
      this.activeUserInfos = JSON.parse(localStorage.getItem('userInfo'))
      this.idOfficine = this.activeUserInfos.IdOfficine

      this.getClientsOfficine(this.idOfficine)
    },
    getClientsOfficine (id) {
      this.$store.dispatch('client/getClientsOfficine', id)
        .then((response) => {
          console.log(response.data)
        })
        .catch((error) => { console.log(error) })
    },
    getClientById (id) {
      this.$vs.loading({
        type: 'sound'
      })
      this.$store.dispatch('client/getClientById', id)
        .then((resp) => {
          this.client = resp.data
          this.IdClient = this.client._id
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    }
  },
  created () {
    this.activeUserInfo()
    this.getPrescripteurs()
  }
}
</script>
<style scoped>
.not-data-table{
  display: none;
}

/* table {
  border:   1px solid black;
} */
th, td {
  border: 1px solid black;
  padding: 15px;
}

.no-padding {
  padding: 0px !important;
}

.prescription {
  display: inline-block;
}
</style>
